'use client';

import useCheckAbuse from '@haaretz/s-article-hooks/useCheckAbuse';
import useDetectAdBlock from '@haaretz/s-article-hooks/useDetectAdBlock';
import useNativeAppInitialization from '@haaretz/s-article-hooks/useNativeAppInitialization';
import { useSetArticleId } from '@haaretz/s-atoms/articleId';
import { useSetAuthors } from '@haaretz/s-atoms/authors';
import { useMutatorsClientTeasersOnPage } from '@haaretz/s-atoms/clientTeasersOnPage';
import { useSetCookie } from '@haaretz/s-atoms/cookie';
import { useSetPage } from '@haaretz/s-atoms/page';
import { useSetPageStatusToken } from '@haaretz/s-atoms/pageStatusToken';
import { useSetPageType } from '@haaretz/s-atoms/pageType';
import { useSetPaywallType } from '@haaretz/s-atoms/paywallType';
import { useSetPreviewId } from '@haaretz/s-atoms/previewId';
import { useSetRenderingKind } from '@haaretz/s-atoms/renderingKind';
import { useSetTags } from '@haaretz/s-atoms/tags';
import useUser from '@haaretz/s-atoms/user';
import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';
import getK5aMeta from '@haaretz/s-page-utils/getK5aMeta';
import getArticleSectionId from '@haaretz/s-server-data-fetchers/getArticleSectionId';
import useBackToSection from '@haaretz/s-use-back-to-section';
import useBi from '@haaretz/s-use-bi';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import { useSearchParams } from 'next/navigation';
import React from 'react';

import ProvokeAdBlock from '../ProvokeAdBlock';

import type { UseDetectAdBlockProps } from '@haaretz/s-article-hooks/useDetectAdBlock';
import type { BiDataOverrides, Breadcrumbs } from '@haaretz/s-data-structure-types';
import type { BaseArticleFragment } from '@haaretz/s-fragments/articles/Base.article';
import type { AuthorFragment } from '@haaretz/s-fragments/Author';
import type { ImmutablePageDataFragment } from '@haaretz/s-fragments/ImmutablePageData';
import type { SeoDataFragment } from '@haaretz/s-fragments/SeoData';
import type { TagPageFragment } from '@haaretz/s-fragments/TagPage';
import type { PageType, RenderingKind, PaywallType, PlatformType } from '@haaretz/s-types';

const requestBiData: BiDataOverrides = {};

interface CommonUtilsProps extends UseDetectAdBlockProps {
  articleId: string | null;
  pageType: PageType;
  previewId: string | null;
  paywallType: PaywallType;
  renderingKind: RenderingKind;
  authors: Pick<AuthorFragment, 'contentId' | 'name'>[] | null;
  pageStatusToken: string | null;
  title: SeoDataFragment['metaTitle'];
  tags: Pick<TagPageFragment, 'contentId' | 'name'>[] | null;
  pageContentId: ImmutablePageDataFragment['contentId'];
  breadcrumbs: Breadcrumbs | null;
  backButtonEnabled: boolean | null | undefined;
  backButtonNumberOfVisits: number | null | undefined;
  backButtonSectionUrl: string | null | undefined;
  backButtonUserType: string[] | null | undefined;
  platform: PlatformType;
  publishDate: BaseArticleFragment['datePublishedISO'];
  modifiedDate?: BaseArticleFragment['dateModifiedISO'];
}

export default function CommonUtils({
  pageContentId,
  pageType,
  paywallType,
  renderingKind,
  previewId,
  authors,
  articleId,
  pageStatusToken,
  tags,
  title,
  breadcrumbs,
  backButtonEnabled,
  backButtonNumberOfVisits,
  backButtonSectionUrl,
  backButtonUserType,
  platform,
  publishDate,
  modifiedDate,
}: Readonly<CommonUtilsProps>) {
  const biRequest = useBi('pageview');
  const user = useUser('cookieValue');
  const searchParams = useSearchParams();

  const featuredLiveBlogItemId = searchParams.get('liveBlogItemId');

  useNativeAppInitialization();
  useDetectAdBlock({ paywallType });

  useBackToSection({
    enabled: backButtonEnabled,
    sectionUrl: backButtonSectionUrl,
    numberOfVisits: backButtonNumberOfVisits,
    userType: backButtonUserType,
  });

  useCheckAbuse();

  const setPageType = useSetPageType();
  const setPagePaywallLevel = useSetPaywallType();
  const setRenderingKind = useSetRenderingKind();
  const setAuthors = useSetAuthors();
  const setTags = useSetTags();
  const setPage = useSetPage();
  const setCookie = useSetCookie();
  const setPreviewId = useSetPreviewId();
  const setArticleId = useSetArticleId();
  const setPageStatusToken = useSetPageStatusToken();
  const isLoaded = useLoadEvent();
  const { clearClientTeasers } = useMutatorsClientTeasersOnPage();

  React.useEffect(() => {
    setPageType(pageType);
  }, [pageType, setPageType]);

  React.useEffect(() => {
    setPagePaywallLevel(paywallType);
  }, [paywallType, setPagePaywallLevel]);

  React.useEffect(() => {
    setRenderingKind(renderingKind);
  }, [renderingKind, setRenderingKind]);

  React.useEffect(() => {
    setAuthors(authors || null);
  }, [authors, setAuthors]);

  React.useEffect(() => {
    setTags(tags || null);
  }, [tags, setTags]);

  React.useEffect(() => {
    setPageStatusToken(pageStatusToken);
  }, [pageStatusToken, setPageStatusToken]);

  React.useEffect(() => {
    setPage({
      pageContentId,
      articleSectionId: getArticleSectionId(breadcrumbs) ?? undefined,
    });
  }, [breadcrumbs, pageContentId, setPage]);

  React.useEffect(() => {
    setPreviewId(previewId);
  }, [previewId, setPreviewId]);

  React.useEffect(() => {
    setArticleId(articleId);
  }, [articleId, setArticleId]);

  React.useEffect(() => {
    if (articleId) {
      window._tfa = window._tfa || [];

      window._tfa.push({ notify: 'newPageLoad' });
    }
  }, [articleId]);

  React.useEffect(() => {
    window.k5aMeta = {
      ...getK5aMeta({
        authors,
        platform,
        pageType,
        paywallType,
        publishDate,
        modifiedDate,
        breadcrumbs:
          /* istanbul ignore next */
          breadcrumbs?.map(breadcrumb => breadcrumb?.body || '') || [],
        tags,
        title,
        renderingKind,
        user,
        featuredLiveBlogItemId,
      }),
    };

    const spaReadyEvent: CustomEvent<never> = new CustomEvent('K5A:SpaReady');

    document.dispatchEvent(spaReadyEvent);
  }, [
    authors,
    breadcrumbs,
    featuredLiveBlogItemId,
    modifiedDate,
    pageType,
    paywallType,
    platform,
    publishDate,
    renderingKind,
    tags,
    title,
    user,
  ]);

  React.useEffect(() => {
    clearClientTeasers();
  }, [articleId, clearClientTeasers]);

  React.useEffect(() => {
    if (!!articleId && !!biRequest && isLoaded) {
      const timeout = setTimeout(() => biRequest(requestBiData), 500);

      return () => timeout && clearTimeout(timeout);
    }

    return undefined;
  }, [articleId, biRequest, isLoaded]);

  /* istanbul ignore next */
  const onPathChangedCb = React.useCallback(() => {
    setCookie();
  }, [setCookie]);

  usePathChanged(onPathChangedCb);

  return <ProvokeAdBlock />;
}
